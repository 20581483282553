import React, { useEffect, useState } from 'react'
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
  Box,
  Tooltip,
  Menu,
  MenuItem, Typography, Container,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { EnhancedTableProps } from '@propTypes/index';
import { useTableStore } from 'state/TableStore';
import { useLocation } from 'react-router-dom';
import { CustomFilter } from './CustomFilter';
import { extractPageName } from '../helpers';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FilterListIcon from '@mui/icons-material/FilterList';

export const CustomTableHead: React.FC<EnhancedTableProps> = (props: EnhancedTableProps) => {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    label,
    handleInternalSelectAll,
    hoverLabel,
    setHoverLabel,
    selectAllState,
    currentPage,
    visibleRows,
    tableName,
    tableConfig,
    tableBgColor
  } = props;

  const { tableHeaderCells, tableHeaderSorting } = tableConfig![tableName!]

  console.log('debug columnStateReducer tableConfig: ', tableConfig);

  const columnState = useTableStore(state => state.columnState)
  const headerButtonConfig = useTableStore(state => state.headerButtonConfig)
  const setHeaderButtonConfig = useTableStore(state => state.setHeaderButtonConfig)
  const customFilterAnchorEl = useTableStore(state => state.customFilterAnchorEl)
  const setCustomFilterAnchorEl = useTableStore(state => state.setCustomFilterAnchorEl)
  const setCurrentDataIndex = useTableStore(state => state.setCurrentDataIndex)
  const setSelected = useTableStore(state => state.setSelected)
  const currentDataIndex = useTableStore(state => state.currentDataIndex)

  const locationObj = useLocation()
  const pageName = extractPageName(locationObj.pathname)
  const [currentCell, setCurrentCell] = useState<string>("")
  const [checked, setChecked] = React.useState(false)
  const [hoverFullName, setHoverFullName] = useState(false)
  const [hoverStatus, setHoverStatus] = useState(false)
  const [hoverSecurity, setHoverSecurity] = useState(false)
  const [sortSelectAnchorEl, setSortSelectAnchorEl] = useState(null)

  const openFilterMenu = Boolean(sortSelectAnchorEl)
  const popoverId = openFilterMenu ? 'actions-popover' : undefined;

  console.log('debug CustomTableHead tableHeaderCells: ', { tableHeaderCells, columnState })

  const createSortHandler = (event: any, property: any) => {
    console.log(`debug sort createSortHandler running property: ${property}`)
    if (property === "fullName") {
      property = orderBy
    }
    handleClose(event)
    if (event.target.checked !== true && event.target.checked !== false) {
      onRequestSort(event, property);
    }
  };

  console.log('tableHeaderCells CustomTableHead: ', tableHeaderCells)

  const headerCellsLength = tableHeaderCells?.length || 0
  const widthPercentage = 100 / headerCellsLength

  const tableHeadCellStyle = {
    backgroundColor: tableBgColor,
    height: "56px",
    minWidth: label !== "batch-printing" ? `${widthPercentage}%` : "",
    top: 0,
    zIndex: 2,
  }

  //Calculate checked status 
  useEffect(() => {
    if (selectAllState?.pages.some((element: any) => element.page === currentPage)) {
      setChecked(selectAllState?.pages.find((element: any) => element.page === currentPage).selectAll)
    } else {
      setChecked(false)
    }
  }, [currentPage])

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map((n: any) => n.transactionNumber);
      if (newSelected.length > 0) {
        setHeaderButtonConfig({ ...headerButtonConfig, disabled: false })
      } else {
        setHeaderButtonConfig({ ...headerButtonConfig, disabled: false })
      }
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const customIconHandler = (e: any, cell: string) => {
    console.log(`debug ONLY customFilter  icon handler cell ${cell} event: `, e)
    e.stopPropagation()
    if (pageName.includes("rapback") && !pageName.includes("summary")) {
      setSortSelectAnchorEl(e.target)
    } else {
      setCustomFilterAnchorEl(e.target)
    }
    setCurrentDataIndex(cell)
  }

  const tablesToPad = ["transaction-summary", "rapback-validations", "rapback-prenotifications", "rapback-summary", "agency-management", "user-management", "applicant-details"]

  const handleClose = (e) => {
    e.stopPropagation()
    setSortSelectAnchorEl(null)
    setCurrentCell("")
  }

  const handleKeyDown = (event: any, property: string) => {
    if (event.key === 'Enter') {
      createSortHandler(event, property)
    }
  };

  const SortSelect: React.FC<any> = ({ dataIndex }) => {
    return (
      <Menu
        //sx={{width: "261px", height: "196px", bordRadius: 5, gap: "16px"}}
        elevation={10}
        onClose={(e) => handleClose(e)}
        slotProps={{ paper: { sx: { width: "200px", minWidth: 'max-content', backgroundColor: "background.default", border: "none", display: 'flex', flexDirection: "column" } } }}
        id={popoverId}
        open={openFilterMenu && currentDataIndex === dataIndex}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={sortSelectAnchorEl}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}>

        <Container disableGutters onMouseLeave={(e) => handleClose(e)}>
          <MenuItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <Container onClick={(e) => e.stopPropagation()} disableGutters sx={{ width: "100%", display: "flex", justifyContent: "space-evenly" }}>
              <Typography>Sort by First Name</Typography>
              {
                order === "asc"
                  ? <Tooltip title="sort dsc">
                    <ArrowUpwardIcon onClick={(e) => createSortHandler(e, "firstName")} onKeyDown={(e) => handleKeyDown(e, "firstName")} sx={{ height: "20px", width: "20px", color: 'text.secondary' }} />
                  </Tooltip>
                  : <Tooltip title="sort asc">
                    <ArrowDownwardIcon onClick={(e) => createSortHandler(e, "firstName")} onKeyDown={(e) => handleKeyDown(e, "firstName")} sx={{ height: "20px", width: "20px", color: 'text.secondary' }} />
                  </Tooltip>
              }
            </Container>
          </MenuItem>

          <MenuItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
            <Container onClick={(e) => e.stopPropagation()} disableGutters sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <Typography>Sort by Last Name</Typography>
              {
                order === "asc"
                  ? <Tooltip title="sort dsc">
                    <ArrowUpwardIcon onClick={(e) => createSortHandler(e, "lastName")} onKeyDown={(e) => handleKeyDown(e, "firstName")} sx={{ height: "20px", width: "20px", color: 'text.secondary' }} />
                  </Tooltip>
                  : <Tooltip title="sort asc">
                    <ArrowDownwardIcon onClick={(e) => createSortHandler(e, "lastName")} onKeyDown={(e) => handleKeyDown(e, "firstName")} sx={{ height: "20px", width: "20px", color: 'text.secondary' }} />
                  </Tooltip>
              }
            </Container>
          </MenuItem>
        </Container >
      </Menu >
    )
  }

  const pageIsRbSub = pageName === "rapback-maintenance" || pageName === "rapback-prenotifications" || pageName === "rapback-validations"

  const handleMouseEnter = (cell) => {
    setCurrentDataIndex(cell.dataIndex)
    if (pageName != "applicant-details" && tableName != 'rb-sub-audit-history' && tableName != "reject-codes") {
      if (pageName === "user-management") {
        if (cell.dataIndex != "securityLevel" && cell.dataIndex != "status") {
          setHoverLabel(cell.dataIndex)
        } else if (cell.dataIndex === "status") {
          setHoverStatus(true)
        } else if (cell.dataIndex === "securityLevel") {
          setHoverSecurity(true)
        }
      } else if (pageIsRbSub) {
        if (cell.dataIndex != "fullName") {
          setHoverLabel(cell.dataIndex)
        } else {
          setHoverFullName(true)
        }
      } else {
        setHoverLabel(cell.dataIndex)
      }
    }
  }

  const handleMouseLeave = (cell) => {
    setCurrentDataIndex("")
    if (pageName != "applicant-details" && tableName != 'rb-sub-audit-history' && tableName != "reject-codes") {
      if (pageName === "user-management") {
        if (cell.dataIndex != "securityLevel" && cell.dataIndex != "status") {
          setHoverLabel("")
        } else if (cell.dataIndex === "status") {
          setHoverStatus(false)
        } else if (cell.dataIndex === "securityLevel") {
          setHoverSecurity(false)
        }
      } else if (pageIsRbSub) {
        if (cell.dataIndex != "fullName") {
          setHoverLabel("")
        } else {
          setHoverFullName(false)
        }
      } else {
        setHoverLabel("")
      }
    }
  }
  const zoomLevel = useTableStore(state => state.zoomLevel)

  return (
    <TableHead>
      <TableRow>
        {
          tableHeaderCells?.map((cell: any, index: number) => {
            return (
              columnState.page[pageName][tableName!]?.columnVisibility[cell.dataIndex] && (
                <TableCell
                  sx={label !== "batch-printing"
                    ? cell.dataIndex === 'actions'
                      ? { width: '1%', backgroundColor: tablesToPad.includes(pageName) ? "common.black" : "background.default" }
                      : tableHeadCellStyle
                    : { paddingLeft: 0 }}
                  key={`cell-${index}`}
                  align={'left'}
                  padding={'none'}
                  sortDirection={orderBy === cell.dataIndex ? order : false}
                  onClick={(e) => {
                    if (tableHeaderSorting && cell.dataIndex !== "fullName") {
                      createSortHandler(e, cell.dataIndex)
                    }
                  }
                  }
                  onKeyDown={(e) => handleKeyDown(e, cell.dataIndex)}
                  tabIndex={0}
                >
                  <TableSortLabel
                    disabled={cell.dataIndex === "hitNoHit" || cell.dataIndex === "actions" || !tableHeaderSorting ? true : false}
                    key={`${tableName}-${index}`}
                    hideSortIcon={cell.dataIndex !== hoverLabel}
                    onMouseEnter={() => handleMouseEnter(cell)}
                    onMouseLeave={() => handleMouseLeave(cell)}
                    sx={{ width: "100%", fontSize: `${0.875 / zoomLevel}rem` , pl: '1rem', '&.MuiTableSortLabel-root': { cursor: !tableHeaderSorting ? "default" : "pointer", display: "flex", justifyContent: "space-between", paddingLeft: 0 } }}
                    active={orderBy === cell.dataIndex || ((orderBy === "firstName" || orderBy === "lastName") && cell.dataIndex === "fullName")}
                    direction={orderBy === cell.dataIndex ? order : "asc"}
                    tabIndex={-1}
                    onClick={(e: any) => {
                      console.log('debug click label: ', { renderCustomSortIcon: cell.renderCustomSortIcon, cell })
                      cell.renderCustomSortIcon
                        ? customIconHandler(e, cell.dataIndex)
                        : (cell.dataIndex != "fullName" && pageName != "user-management") || (pageName === "user-management") && createSortHandler(e, cell.dataIndex)
                    }}
                    onKeyDown={(e: any) => {
                      cell.renderCustomSortIcon
                        ? customIconHandler(e, cell.dataIndex)
                        : (cell.dataIndex != "fullName" && pageName != "user-management") || (pageName === "user-management") && handleKeyDown(e, cell.dataIndex)
                    }}
                    IconComponent={(iconE: any) => (
                      ((pageName === "user-management" && cell.dataIndex != "status" && cell.dataIndex != "securityLevel") || (pageName != "user-management")) &&
                        (order === "asc" && orderBy === cell.dataIndex) || (order === "asc" && orderBy === cell.dataIndex && pageName === "user-management") || (order === "asc" && orderBy === "firstName" || order === "asc" && orderBy === "lastName") && cell.dataIndex === "fullName"
                        ? <Tooltip title={`sort ${cell.dataIndex === "fullName" && pageName != "user-management" && orderBy === "firstName" ? "first name" : cell.dataIndex === "fullName" && pageName != "user-management" && orderBy === "lastName" ? "last name" : ""} dsc`}>
                          <ArrowUpwardIcon onClick={(e) => createSortHandler(e, cell.dataIndex)} onKeyDown={(e) => handleKeyDown(e, cell.dataIndex)} sx={{ marginRight: 2, height: `${20 / zoomLevel}px`, width: `${20 / zoomLevel}px`, color: 'text.secondary' }} />
                        </Tooltip>
                        : <Tooltip title={`sort ${cell.dataIndex === "fullName" && pageName != "user-management" && orderBy === "firstName" ? "first name" : cell.dataIndex === "fullName" && pageName != "user-management" && orderBy === "lastName" ? "last name" : ""} asc`}>
                          <ArrowDownwardIcon onClick={(e) => createSortHandler(e, cell.dataIndex)} onKeyDown={(e) => handleKeyDown(e, cell.dataIndex)} sx={{ marginRight: 2, height: `${20 / zoomLevel}px`, width: `${20 / zoomLevel}px`, color: 'text.secondary' }} />
                        </Tooltip>
                    )}
                  >
                    {
                      orderBy === cell.id
                        ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc" ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        )
                        : null
                    }
                    <Box sx={
                      cell.dataIndex === "fullName" || (pageName === "user-management" && cell.dataIndex === "status") || (pageName === "user-management" && cell.dataIndex === "securityLevel")
                        ? { display: "flex", width: "100%", justifyContent: "space-between" }
                        : {}
                    }>
                      {
                        // select all button 
                        label === 'batch-printing' && cell.dataIndex === "checkbox"
                          ?
                          <Checkbox key={index}
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={
                              selectAllState?.pages.some((element: any) => element.page === currentPage)
                                ? selectAllState?.pages.find((element: any) => element.page === currentPage).selectAll
                                : false
                            }
                            onChange={(e) => { e.preventDefault(); e.stopPropagation(); handleSelectAll(e); handleInternalSelectAll(e) }}
                            inputProps={{
                              'aria-label': 'select all',
                            }}
                          />
                          : null
                      }
                      <Typography variant="subtitle2" sx={{fontSize: `${14 / zoomLevel}px`}}>
                        {cell.label}
                      </Typography>
                      {
                        tableName!.includes('rapback') && !tableName!.includes("summary") && cell.dataIndex === "fullName" && hoverFullName && /* orderBy != "firstName" && orderBy != "lastName"*/
                        <MoreVertIcon onClick={(e) => customIconHandler(e, cell.dataIndex)} />

                      }
                      {
                        cell.dataIndex === "securityLevel" && hoverSecurity
                        && (<FilterListIcon onClick={(e) => customIconHandler(e, cell.dataIndex)} />)

                      }
                      {
                        cell.dataIndex === "status" && hoverStatus
                        && (<FilterListIcon onClick={(e) => customIconHandler(e, cell.dataIndex)} />)
                      }
                    </Box>
                    {
                      <CustomFilter
                        tableName={tableName}
                        tableConfig={tableConfig}
                        index={index}
                        key={index}
                        tableFilter={true}
                        enableHeaderButton={false}
                        enableFooterButtons={false}
                        dataIndex={cell.dataIndex}
                        setCurrentCell={setCurrentCell}
                        currentCell={currentCell}
                        customFilterAnchorEl={customFilterAnchorEl}
                        setCustomFilterAnchorEl={setCustomFilterAnchorEl} />
                    }
                    {
                      <SortSelect dataIndex={cell.dataIndex} />
                    }
                  </TableSortLabel>
                </TableCell>
              ))
          }
          )
        }
      </TableRow>
    </TableHead>
  );
}

